export function item(state) {
  return state.item
}

export function items(state) {
  return state.items
}

export function pagination(state) {
  return state.pagination
}

export function products(state) {
  return state.products
}

export function salesAmount(state) {
  return state.salesAmount
}

export function salesNotification(state) {
  return state.salesNotification
}

export function sales(state) {
  return state.sales
}
export function sale(state) {
  return state.sale
}
