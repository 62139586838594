import { Notify } from 'quasar'
import { boot } from 'quasar/wrappers'

Notify.setDefaults({
  position: 'top',
  textColor: 'white',
  timeout: 8000
})

export default boot(({ app }) => {
  Notify.error = (message, position = 'top') => {
    Notify.create({
      color: 'red-5',
      message,
      position,
      icon: 'eva-alert-circle-outline',
      actions: [{ icon: 'eva-close', handler: Function, color: 'red-3' }]
    })
  }

  Notify.success = (message, position = 'top') => {
    Notify.create({
      color: 'green-6',
      message,
      position,
      icon: 'eva-checkmark',
      actions: [{ label: 'Cerrar', handler: Function, color: 'green-3' }]
    })
  }

  Notify.default = (message, position = 'top') => {
    Notify.create({
      color: 'green-6',
      message,
      position,
      icon: 'eva-checkmark',
      actions: [{ label: 'Cerrar', handler: Function, color: 'green-3' }]
    })
  }
  app.config.globalProperties.$notify = Notify
})
