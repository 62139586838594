export default function () {
  return {
    item: {},
    items: [],
    pagination: {},
    salesAmount: {},
    sales: [],
    sale: {},
    products: {
      branch: [],
      others: []
    },
    salesNotification: [],
  }
}
