import { boot } from 'quasar/wrappers'
import { Cookies } from 'quasar'
import { api } from './axios'

export default boot(({ store, ssrContext }) => {
  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies
  if (cookies.has('auth_token')) {
    store.dispatch('auth/isAuthenticated', true)
    api.defaults.headers.common['Authorization'] =
    'Bearer ' + cookies.get('auth_token')
  } else { 
    store.dispatch('auth/isAuthenticated', false)
  }
})
