import { boot } from 'quasar/wrappers'
import axios from 'axios'
import { Notify } from 'quasar'
import getError from 'src/utils/errors-catalog.js'

const api = axios.create({
  baseURL: process.env.API
})

export default boot(({ app, store, router }) => {
  // for use inside Vue files (Options API) through this.$axios and this.$api

  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API

  api.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (router.currentRoute.name == 'recover') {
        Notify.error('Este correo no se encuentra registrado')
      } else {
        if (error.response.status == 401) {
          store.dispatch('auth/clear')
        }
        const fields =
          error.response.data.message && error.response.data.message.fields
            ? error.response.data.message.fields
            : error.response.data.fields
            ? error.response.data.fields
            : []

        if (router.currentRoute?._value?.name != 'payments') {
          Notify.error(
            getError(error.response.status, error.response.data.code, fields)
          )
        }
        if (
          router.currentRoute?._value?.name == 'payments' &&
          error.response.data.code == 'INVALID_QUANTITY'
        ) {
          Notify.error(
            getError(error.response.status, error.response.data.code, fields)
          )
        }
      }

      return Promise.reject(error)
    }
  )
})

export { api }
