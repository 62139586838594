import { api } from 'boot/axios.js'

//rating seller
export async function sellerRating(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `seller-rating/${queryParams.id}`,
      method: 'GET'
    })
    let rating = 0
    if (response.data && response.data.rating) {
      rating = parseFloat(response.data.rating)
      rating = rating > 0 ? rating.toFixed(1) : rating
      rating = parseFloat(response.data.rating)
    }
    ctx.commit('sellerRatingAverage', rating)
  } catch (e) {
    throw e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}
