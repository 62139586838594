export function items(state) {
  return state.items
}

export function pagination(state) {
  return state.pagination
}

export function item(state) {
  return state.item
}

export function home(state) {
  return state.home
}

export function brands(state) {
  return state.brands
}

export function priceRange(state) {
  return state.priceRange
}

export function isFavorite(state) {
  return state.isFavorite
}

export function minimumPrice(state) {
  return state.minimumPrice
}

export function fee(state) {
  return state.fee
}

export function moreProductsSeller(state) {
  return state.moreProductsSeller
}

export function highlight(state) {
  return state.highlight
}

export function product(state) {
  return state.product
}
