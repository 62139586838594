import { api } from 'boot/axios.js'
import { getCookies } from 'boot/cookies.js'
export async function me(ctx) {
  try {
    const cookies = getCookies
    if (cookies?.has('auth_token')) {
      const response = await api({
        url: '/auth/me',
        method: 'GET'
      })
      ctx.commit('me', response.data)
      return response
    }
  } catch (e) {
    // const cookies = getCookies
    // cookies?.remove('auth_token')
    // cookies?.remove('auth_token_life')
    // cookies?.remove('auth_token_type')

    // cookies?.remove('refresh_token')
    // cookies?.remove('refresh_token_life')

    // delete api.defaults.headers.common['Authorization']
    return e
  }
}

export function logout(ctx) {
  ctx.commit('me', {})
}
