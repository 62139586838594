import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/categories',
      params: queryParams,
      method: 'get'
    })
    const items = response.data.items
    const treeLines = []
    const lines = items.filter(c => c.parentId === null)
    const reorder = items.filter(
      c => c.name.includes('Ropa') && c.parentId === null
    )
    const indexReplace = lines.findIndex(
      c => c.name.includes('Ropa') && c.parentId === null
    )
    const order = reorder.sort(function (a, b) {
      if (a.id > b.id) {
        return 1
      }
      if (a.id < b.id) {
        return -1
      }
    })
    lines.splice(indexReplace, order.length, ...order)
    for (const line of lines) {
      let cloneLine = JSON.parse(JSON.stringify(line))
      cloneLine.children = []
      const filteredCategories = items.filter(
        c => parseInt(c.parentId) === cloneLine.id
      )
      for (const category of filteredCategories) {
        let cloneCategory = JSON.parse(JSON.stringify(category))
        cloneCategory.children = []
        const subcategories = items.filter(
          c => parseInt(c.parentId) === cloneCategory.id
        )
        for (const subcategory of subcategories) {
          const cloneSubcategory = JSON.parse(JSON.stringify(subcategory))
          cloneCategory.children.push(cloneSubcategory)
        }
        cloneLine.children.push(cloneCategory)
      }
      treeLines.push(cloneLine)
    }
    const cloneTreeLines = treeLines.sort((a, b) => {
      if (a.position > b.position) {
        return 1
      }
      if (a.position < b.position) {
        return -1
      }
      return 0
    })
    for (let category = 0; category < cloneTreeLines.length; category++) {
      cloneTreeLines[category].children = cloneTreeLines[
        category
      ].children.sort((a, b) => {
        if (a.position > b.position) {
          return 1
        }
        if (a.position < b.position) {
          return -1
        }
        return 0
      })
      if (cloneTreeLines[category].children.length) {
        for (
          let subCategory = 0;
          subCategory < cloneTreeLines[category].children.length;
          subCategory++
        ) {
          cloneTreeLines[category].children[subCategory].children =
            cloneTreeLines[category].children[subCategory].children.sort(
              (a, b) => {
                if (a.position > b.position) {
                  return 1
                }
                if (a.position < b.position) {
                  return -1
                }
                return 0
              }
            )
        }
      }
    }

    ctx.commit('items', items)
    ctx.commit('pagination', response.data.pagination)
    ctx.commit('tree', cloneTreeLines)
    return response
  } catch (e) {
    return e
  }
}

export async function featuredCategories(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/categories',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('featuredCategories', response.data.items)
    return response
  } catch (e) {
    return e
  }
}

export async function saveMenuCategories(ctx, data) {
  try {
    ctx.commit('menuCategory', data)
    return response
  } catch (e) {
    return e
  }
}

export async function cleanCategorySelection(ctx, data) {
  try {
    ctx.commit('clean', data)
    return response
  } catch (e) {
    return e
  }
}

export async function isClean(ctx, data) {
  try {
    ctx.commit('isClean', data)
    return response
  } catch (e) {
    return e
  }
}
