import { Loading } from 'quasar'
import { boot } from 'quasar/wrappers'
import { LocalStorage } from 'quasar'

export default boot(({ router, store, redirect }) => {
  router.beforeEach(async (to, from, next) => {
    Loading.show()
    if (!process.env.SERVER) {
      window.scrollTo(0, 0)
    }
    if (store.getters['auth/isAuthenticated']) {
      await store.dispatch('user/me')
      // inicio cambio para notificaciones
      await store.dispatch('notifications/query', {
        'filters[where][viewed][eq]': false,
        sort: 'createdAt desc',
        limit: 1000
      })
      // finaliza cambio para notificaciones
      // let response = await store.dispatch('user/me')
      // if (!response.data) {

      //   if (
      //     // la primera vez que lo saca de la sesion e intenta ver alguna seccion de estas
      //     //lo saca aqui ya que aun no se refleja el localstorage de [isAuthenticated]
      //     [
      //       'my-profile',
      //       'my-favorites',
      //       'my-orders',
      //       'messages',
      //       'purchase-disputes',
      //       'my-store',
      //       'messages-seller',
      //       'questions',
      //       'dispute-seller',
      //       'my-balance'
      //     ].includes(to.name)
      //   ) {
      //     Loading.show()

      //     // router.push({ name: 'home' })
      //   }
      // }
      if (to.name === 'publish' || to.name === 'my-shopping-cart') {
        LocalStorage.set('routeAux', to.name)
      } else {
        LocalStorage.set('routeAux', '')
      }
    } else if (
      to.name === 'my-shopping-cart' ||
      to.name === 'order-confirmation' ||
      to.name === 'publish' ||
      to.name === 'address' ||
      to.name === 'edit-address' ||
      to.name === 'select-address'
    ) {
      // Loading.show()
      if (
        to.name !== 'address' ||
        to.name !== 'edit-address' ||
        to.name !== 'select-address'
      ) {
        store.dispatch('app/toNav', to.name)
      }
      redirect({ name: 'login' })
    } else if (
      // la segunda vez en adelante que se intenta acceder cuando lo saco de la sesión a
      //alguna de estas paginas ya entra aqui
      to.name == 'my-profile' ||
      to.name == 'my-favorites' ||
      to.name == 'my-orders' ||
      to.name == 'messages' ||
      to.name == 'purchase-disputes' ||
      to.name == 'my-store' ||
      to.name == 'messages-seller' ||
      to.name == 'questions' ||
      to.name == 'dispute-seller' ||
      to.name == 'my-balance'
    ) {
      // Loading.show()
      router.push({ name: 'home' })
    }
    if (from.name != 'product-detail' || to.name != 'product-detail') {
      // Loading.show()
    } else {
      store.dispatch('app/appIsLoading', true)
    }
    if (to.name === 'publish' || to.name === 'my-shopping-cart') {
      LocalStorage.set('routeAux', to.name)
    }
    next()
  })

  router.afterEach(() => {
    store.dispatch('app/appIsLoading', false)
    Loading.hide()
  })
})
