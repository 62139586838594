import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/media-products',
      params: queryParams,
      method: 'GET'
    })
    const items = response.data.items
    const gallery = items.filter(i => i.section == 'GALLERY')
    const download = items.filter(i => i.section == 'DATA-SHEET')
    ctx.commit('items', items)
    ctx.commit('gallery', gallery)
    ctx.commit('download', download)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('media-products', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function setMediaGallery(ctx, data) {
  try {
    ctx.commit('mediaGallery', data)
    return response
  } catch (e) {
    return e
  }
}

export async function setMediaDownload(ctx, data) {
  try {
    ctx.commit('mediaDownload', data)
    return response
  } catch (e) {
    return e
  }
}

export async function features(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/media-products',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('features', response.data.items)
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}

export async function save(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/item-media',
      data: queryParams,
      method: 'POST'
    })
    return response
  } catch (e) {
    return e
  }
}
export async function update(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/admin/item-media/${queryParams.id}`,
      data: queryParams,
      method: 'PUT'
    })
    return response
  } catch (e) {
    return e
  }
}
export async function deleteImg(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/admin/item-media/${queryParams.id}`,
      method: 'DELETE'
    })
    return response
  } catch (e) {
    return e
  }
}