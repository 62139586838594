import { api } from 'boot/axios.js'

//rating product
export async function itemRating(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/item-rating', queryParams),
      params: queryParams,
      method: 'PUT'
    })
    return response
  } catch (e) {
    throw e
  }
}

//rating seller
export async function sellerRating(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/seller-rating', queryParams),
      params: queryParams,
      method: 'PUT'
    })
    return response
  } catch (e) {
    throw e
  }
}

//rating currier
export async function courierRating(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/courier-rating', queryParams),
      params: queryParams,
      method: 'PUT'
    })
    return response
  } catch (e) {
    throw e
  }
}

export async function sellerRatingAverage(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/seller-rating-average',
      params: queryParams,
      method: 'GET'
    })
    let rating = 0
    if (response.data && response.data.rating) {
      rating = parseFloat(response.data.rating)
      rating = rating > 0 ? rating.toFixed(1) : rating
      rating = parseFloat(response.data.rating)
    }
    ctx.commit('sellerRatingAverage', rating)
    return response
  } catch (e) {
    return e
  }
}

export async function sellerRatings(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/seller-ratings',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}
