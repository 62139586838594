import { api } from 'boot/axios.js'

export async function sellerTerms(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/seller-terms',
      params: queryParams,
      method: 'get'
    })
    ctx.commit('sellerTerms', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function sellerTermsGet(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/seller-terms/${queryParams.id}`,
      method: 'get'
    })
    ctx.commit('sellerTerms', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function buyerTerms(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/buyer-terms',
      params: queryParams,
      method: 'get'
    })
    ctx.commit('buyerTerms', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function buyerTermsGet(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/buyer-terms/${queryParams.id}`,
      method: 'get'
    })
    ctx.commit('buyerTerms', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function privacyTerms(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/privacy-terms',
      params: queryParams,
      method: 'get'
    })
    ctx.commit('privacyTerms', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function privacyTermsGet(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/privacy-terms/${queryParams.id}`,
      method: 'get'
    })
    ctx.commit('privacyTerms', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function termsConditions(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/terms-conditions',
      params: queryParams,
      method: 'get'
    })
    ctx.commit('termsConditions', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function termsConditionsGet(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/terms-conditions/${queryParams.id}`,
      method: 'get'
      })
      ctx.commit('termsConditions', response.data)
      // ctx.commit('pagination', response.data.pagination)
      return response
    } catch (e) {
      return e
    }
  }
  export async function usagePolicy(ctx, queryParams = {}) {
    try {
      const response = await api({
        url: '/usage-policy',
        params: queryParams,
        method: 'get'
      })
      ctx.commit('usagePolicy', response.data)
      // ctx.commit('pagination', response.data.pagination)
      return response
    } catch (e) {
      return e
    }
  }