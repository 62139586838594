export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function item(state, data) {
  state.item = data
}

export function home(state, data) {
  state.home = data
}

export function brands(state, data) {
  state.brands = data
}

export function priceRange(state, data) {
  state.priceRange = data
}

export function isFavorite(state, data) {
  state.isFavorite = data
}

export function minimumPrice(state, data) {
  state.minimumPrice = data
}

export function fee(state, data) {
  state.fee = data
}

export function moreProductsSeller(state, data) {
  state.moreProductsSeller = data
}
