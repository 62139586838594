export function item(state) {
  return state.item
}

export function items(state) {
  return state.items
}

export function pagination(state) {
  return state.pagination
}

export function gallery(state) {
  return state.gallery
}

export function download(state) {
  return state.download
}

export function features(state) {
  return state.features
}
