import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/questions',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function queryAdmin(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: 'admin/questions',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('questions', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function getAdmin(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/questions', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/questions',
      method: 'POST',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, data) {
  try {
    const response = await api({
      url: parseUrl('questions', data),
      method: 'PUT',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}
