import { api } from 'boot/axios.js'

export async function save(ctx, data) {
  try {
    ctx.commit('item', data)
    return response
  } catch (e) {
    return e
  }
}
