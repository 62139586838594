import { api } from 'boot/axios.js'
import { getCookies } from 'boot/cookies.js'
export async function query(ctx, queryParams = {}) {
  try {
    const cookies = getCookies
    if (cookies?.has('auth_token')) {
      const response = await api({
        url: '/admin/purchase-messages',
        params: queryParams,
        method: 'GET'
      })
      ctx.commit('items', response.data.items)
      ctx.commit('pagination', response.data.pagination)
      return response
    }
  } catch (e) {
    return e
  }
}

export async function purchaseConversations(ctx, queryParams = {}) {
  try {
    const cookies = getCookies
    if (cookies?.has('auth_token')) {
      const response = await api({
        url: '/admin/purchase-conversations',
        params: queryParams,
        method: 'GET'
      })
      ctx.commit('items', response.data.items)
      ctx.commit('pagination', response.data.pagination)
      return response
    }
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/purchase-messages',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}
export async function view(ctx, data = {}) {
  try {
    const response = await api({
      url: `/admin/purchase-messages/${data}`,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response.data
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/admin/purchase-messages',
      method: 'POST',
      data: data
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}
export async function saveMedias(ctx, data) {
  try {
    const response = await api({
      url: '/admin/message-medias',
      method: 'POST',
      data: data
    })
    // ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export function products(ctx, data) {
  ctx.commit('products', data)
}
