import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/purchases',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('purchases', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/purchases',
      method: 'POST',
      data: data
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export function products(ctx, data) {
  ctx.commit('products', data)
}

export async function salesAmount(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: 'admin/sales',
      params: queryParams,
      method: 'GET'
    })
    if (response.data && response.data.pagination) {
      let metrics = {
        total: parseInt(response.data.pagination.total),
        netSales: 0
      }
      response.data.items.forEach(product => {
        metrics.netSales += parseFloat(product.amount)
      })
      ctx.commit('salesAmount', metrics)
    }
    return response
  } catch (e) {
    return e
  }
}
export async function sales(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: 'admin/sales',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('sales', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function getSales(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/sales', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('sale', response.data)
    return response
  } catch (e) {
    return e
  }
}
export async function salesNotification(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/sales', queryParams),
      params: queryParams,
      method: 'GET'
    })
    if (!queryParams.id) {
      ctx.commit('salesNotification', response.data.items)      
    }
    return response
  } catch (e) {
    return e
  }
}
