import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { LocalStorage, useQuasar } from 'quasar'

import useNotify from 'src/composables/use-notify'
import { async } from '@firebase/util'

const useUtils = () => {
  const store = useStore()
  const $notify = useNotify()

  const tax = computed(() => store.getters['app/tax'])
  const cart = computed(() => store.getters['shoppingCart/cart'])
  const user = computed(() => {
    let me = store.getters['user/me']
    return me.nickname ? `shoppingCart-${me.nickname}` : ''
  })
  const nickname = computed(() => {
    let me = store.getters['user/me']
    return me.nickname ? me.nickname : ''
  })
  // const branches = computed(() => store.getters['branches/items'])
  // const branch = computed(() => store.getters['branches/item'])
  const categories = computed(() => store.getters['categories/items'])

  const sendEvent =
    process.env.CLIENT && process.env.PROD ? inject('sendEvent') : null

  const total = computed(() => {
    let total = 0
    let totalWithTaxes = 0
    let taxes = 0
    let totalShippings = 0
    let totalAmount = 0
    let totalCartProducts = 0
    cart.value.forEach(element => {
      const prices = getPrices(element.product)
      const totalProduct = prices.realPrice * element.quantity
      const totalProductWithTaxes = prices.currentPrice * element.quantity
      const productTaxTotal = prices.taxes * element.quantity
      total = total + totalProduct
      totalWithTaxes = totalWithTaxes + totalProductWithTaxes
      taxes = taxes + productTaxTotal
      totalShippings += element.quantity * element.shipping
      totalCartProducts += element.quantity
    })
    totalAmount = total + totalShippings
    return {
      total,
      totalWithTaxes,
      taxes,
      totalShippings,
      totalAmount,
      totalCartProducts
    }
  })

  const getPrices = product => {
    let price = parseFloat(product.price)
    let oldPrice = null
    let taxes = 0
    if (product.includesTaxes) {
      taxes = price * tax.value
      price = price + taxes
    }
    if (product.promotion) {
      oldPrice = price
      if (product.promotion.type === 'DISCOUNT') {
        price =
          parseFloat(price) -
          parseFloat(price) * (parseFloat(product.promotion.percent) / 100)
      } else if (product.promotion.type === 'PRICE') {
        price = parseFloat(price) - parseFloat(product.promotion.price)
      }
    }
    return {
      realPrice: product.price,
      currentPrice: parseFloat(price.toFixed(2)),
      oldPrice,
      includesTaxes: product.includesTaxes,
      taxes
    }
  }

  const addProductToCart = async (product, qty = 1) => {
    if (qty < 1) {
      $notify.error(
        'Lo sentimos, por el momento no contamos con unidades en existencia de este producto.'
      )
      return
    }
    let cloneCart = JSON.parse(JSON.stringify(cart.value))
    let found = cloneCart.find(c => c.product.id == product.id)
    if (found) {
      let newQty = parseInt(found.quantity) + qty
      if (newQty <= found.stock.total) {
        found.quantity = newQty
      } else {
        found.quantity = found.stock.total
      }
      $notify.success('Tu producto ha sido agregado al carrito.')
      /*
       Inicio de evento de google analytics
       */
      // if (process.env.CLIENT && process.env.PROD) {
      //   const prices = getPrices(found.product)
      //   let categories = {}
      //   const catsProd = getCategoriesByProduct(found.product)
      //   catsProd.forEach((c, i) => {
      //     categories[`item_category${i == 0 ? '' : i + 1}`] = c.name
      //   })
      //   sendEvent('add_to_cart', {
      //     currency: 'MXN',
      //     value: prices.currentPrice,
      //     items: [
      //       {
      //         item_id: found.product.id,
      //         item_name: found.product.name,
      //         currency: 'MXN',
      //         item_brand: found.product.brand,
      //         ...categories,
      //         price: prices.currentPrice,
      //         quantity: found.quantity
      //       }
      //     ]
      //   })
      // }
      /*
      Fin de evento de google analytics
      */
    } else {
      // const branchId = branches.value[0].items[0].id
      // const response = await store.dispatch('warehouse/query', {
      //   productId: product.id,
      //   branchId
      // })
      // const total = response.branch + response.others
      // if (total < 1) {
      //   $notify.error('Lo sentimos, por el momento no contamos con unidades en existencia de este producto.')
      //   return
      // } else if (!product.available) {
      //   $notify.error('Lo sentimos, por el momento no contamos con este producto.')
      //   return
      // }
      cloneCart.push({
        id: product.id,
        product: product,
        quantity: qty,
        url: product.imageAvailable ? product.imageMain : null,
        promotionId: product.promotion ? product.promotion.id : null,
        stock: {
          total: product.quantity
          // ...response
        },
        shipping: 0,
        parcel: {}
      })
      $notify.success('Tu producto ha sido agregado al carrito.')
      /*
       Inicio de evento de google analytics
       */
      // if (process.env.CLIENT && process.env.PROD) {
      //   const prices = getPrices(product)
      //   let categories = {}
      //   const catsProd = getCategoriesByProduct(product)
      //   catsProd.forEach((c, i) => {
      //     categories[`item_category${i == 0 ? '' : i + 1}`] = c.name
      //   })
      //   sendEvent('add_to_cart', {
      //     currency: 'MXN',
      //     value: prices.currentPrice,
      //     items: [
      //       {
      //         item_id: product.id,
      //         item_name: product.name,
      //         currency: 'MXN',
      //         item_brand: product.brand,
      //         ...categories,
      //         price: prices.currentPrice,
      //         quantity: qty
      //       }
      //     ]
      //   })
      // }
      /*
      Fin de evento de google analytics
      */
    }
    store.dispatch('shoppingCart/save', cloneCart)
    LocalStorage.set(user.value, cloneCart)
  }

  const modifyQtyProductCart = (product, qty) => {
    let cloneCart = JSON.parse(JSON.stringify(cart.value))
    let found = cloneCart.find(c => c.product.id == product.id)
    if (found) {
      const _qty = parseInt(qty)
      if (_qty > found.stock.total) {
        found.quantity = found.stock.total
      } else if (_qty < 1) {
        found.quantity = 1
      } else {
        found.quantity = _qty
      }
    }
    store.dispatch('shoppingCart/save', cloneCart)
    LocalStorage.set(user.value, cloneCart)
  }

  const saveShipping = (product, priceShipping = 0, parcel = {}) => {
    let cloneCart = JSON.parse(JSON.stringify(cart.value))
    let found = cloneCart.find(c => c.product.id == product.id)
    if (found) {
      // found.shipping = found.quantity * priceShipping
      found.shipping = priceShipping
      found.parcel = parcel
    }
    store.dispatch('shoppingCart/save', cloneCart)
    LocalStorage.set(user.value, cloneCart)
  }

  const removeProductCart = product => {
    let cloneCart = JSON.parse(JSON.stringify(cart.value))
    let filtered = cloneCart.filter(c => c.product.id != product.id)
    store.dispatch('shoppingCart/save', filtered)
    LocalStorage.set(user.value, filtered)
    /*
      Inicio de evento de google analytics
      */
    // if (process.env.CLIENT && process.env.PROD) {
    //   const prices = getPrices(product)
    //   let categories = {}
    //   const catsProd = getCategoriesByProduct(product)
    //   catsProd.forEach((c, i) => {
    //     categories[`item_category${i == 0 ? '' : i + 1}`] = c.name
    //   })
    //   sendEvent('remove_from_cart', {
    //     currency: 'MXN',
    //     value: prices.currentPrice,
    //     items: [
    //       {
    //         item_id: product.id,
    //         item_name: product.name,
    //         currency: 'MXN',
    //         item_brand: product.brand,
    //         ...categories,
    //         price: prices.currentPrice
    //       }
    //     ]
    //   })
    // }
    /*
  Fin de evento de google analytics
  */
  }

  const updateStockCart = () => {
    // let cloneCart = JSON.parse(JSON.stringify(cart.value))
    // const branchId = branch.value.id
    // cloneCart.forEach(async c => {
    //   const response = await store.dispatch('warehouse/query', {
    //     productId: c.product.id,
    //     branchId
    //   })
    //   c.stock = {
    //     total: response.branch + response.others,
    //     ...response
    //   }
    // })
  }

  const getCategoriesByProduct = product => {
    let breadCategories = []
    let categoryId = product.categoryId
    let currentCat = categories.value.find(c => c.id == categoryId)
    breadCategories.push(currentCat)
    while (currentCat.parentId) {
      categoryId = currentCat.parentId
      currentCat = categories.value.find(c => c.id == categoryId)
      breadCategories.push(currentCat)
    }
    return breadCategories
  }

  const favoriteProduct = async (isFavorite, productId) => {
    if (isFavorite) {
      await deleteFavoriteProduct(productId)
    } else {
      await saveFavoriteProduct(productId)
    }
    await store.dispatch('favorites/query', {
      with: 'image'
    })
  }

  const saveFavoriteProduct = async productId => {
    let response = await store.dispatch('favorites/saveFavorite', {
      productId: productId
    })
    if (response.status == 200) {
      $notify.success('El producto se ha guardado en la lista de favoritos')
    }
    // await store.dispatch('favorites/query', {
    //   with: 'image'
    // })
  }

  const deleteFavoriteProduct = async productId => {
    let response = await store.dispatch('favorites/remove', {
      id: productId
    })
    if (response.status == 202) {
      $notify.success('El producto se ha eliminado de la lista de favoritos')
    }
    // await store.dispatch('favorites/query', {
    //   with: 'image'
    // })
  }

  const compareQuantityProduct = async () => {
    let cloneCart = JSON.parse(JSON.stringify(cart.value))
    let newCart = []
    cloneCart.forEach(async (product, index) => {
      let response = await getProduct(product.id)

      if (
        response.quantity &&
        response.quantity > 0 &&
        response.nickname != nickname.value
      ) {
        let cloneProduct = {
          id: response.id,
          product: response,
          quantity:
            product.quantity <= response.quantity
              ? product.quantity
              : response.quantity,
          url: response.imageAvailable ? response.imageMain : null,
          promotionId: response.promotion ? response.promotion.id : null,
          stock: {
            total: response.quantity
          },
          shipping: 0,
          parcel: {}
        }
        newCart.push(cloneProduct)
      }
      if (index == cloneCart.length - 1) {
        store.dispatch('shoppingCart/save', newCart)
        LocalStorage.set(user.value, newCart)
      }
    })
  }

  const getProduct = async productId => {
    try {
      let response = await store.dispatch('products/getProduct', {
        id: productId,
        with: 'medias'
      })
      if (response.status > 199 && response.status < 300 && response.data) {
        return {
          ...response.data
        }
      }
    } catch (error) {}
  }

  const deepClone = obj => {
    if (obj === null || typeof obj !== 'object') {
      return obj
    }

    if (Array.isArray(obj)) {
      // Si es un array, clonamos cada elemento del array de forma recursiva
      return obj.map(item => deepClone(item))
    }

    // Si es un objeto, clonamos cada propiedad del objeto de forma recursiva
    const clonedObj = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = deepClone(obj[key])
      }
    }

    return clonedObj
  }

  return {
    tax,
    total,
    // Methods
    getPrices,
    addProductToCart,
    modifyQtyProductCart,
    removeProductCart,
    updateStockCart,
    getCategoriesByProduct,
    saveShipping,
    favoriteProduct,
    saveFavoriteProduct,
    deleteFavoriteProduct,
    deepClone,
    compareQuantityProduct
  }
}

export default useUtils
