import { boot } from 'quasar/wrappers'
import CurrencyFilter from 'src/filters/currency'
import PhoneFilter from 'src/filters/phone'
import SiFormat from 'src/filters/si-format'
import DateRelative from 'src/filters/date-relative'

export default boot(({ app }) => {
  app.config.globalProperties.$filters = {
    currency: CurrencyFilter,
    phoneFilter: PhoneFilter,
    siformat: SiFormat,
    dateRelative: DateRelative
  }
})
