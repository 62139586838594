<template>
  <div id="q-app">
    <router-view />
    <!-- <ms-sidebar-cart /> -->
  </div>
</template>
<script>
import MsSidebarCart from './components/_shared/ms-sidebar-cart.vue'

export default {
  name: 'App',
  components: {
    // MsSidebarCart
  },
  computed: {
    sidebarCart() {
      return this.$store.getters['app/sidebarCart']
    }
  },
  watch: {
    sidebarCart(newVal) {
      if (newVal) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  }
}
</script>
