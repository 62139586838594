export function item(state) {
  return state.item
}

export function items(state) {
  return state.items
}

export function pagination(state) {
  return state.pagination
}
