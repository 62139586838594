export default {
  item: {},
  items: [],
  pagination: {},
  tree: [],
  menuCategory: [],
  clean: false,
  featuredCategories: [],
  isClean: false
}
