import { api } from 'boot/axios.js'

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/accounts',
      method: 'POST',
      data: data
    })
    ctx.commit('customer', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, data) {
  try {
    const response = await api({
      url: `/accounts/${data.id}`,
      method: 'PUT',
      data: data.data
    })
    ctx.commit('customer', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function validateAccount(ctx, data) {
  try {
    const response = await api({
      url: `/auth/verify-account/${data}`,
      method: 'GET',
    })
    return response
  } catch (e) {
    return e
  }
}
export async function checkPhone(ctx, data) {
  try {
    const response = await api({
      url: '/check-phone',
      method: 'POST',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}

