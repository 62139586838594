export function me(state, data) {
  state.me = data
}

export function items(state, data) {
  state.items = data
}
export function pagination(state, data) {
  state.pagination = data
}

export function item(state, data) {
  state.item = data
}

export function updateProfile(state, data) {
  state.updateProfile = data
}
