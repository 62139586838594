import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/item-features',
      params: {
        ...queryParams
      },
      method: 'get'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    throw e
  }
}

export async function save(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/item-features',
      data: queryParams,
      method: 'POST'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/admin/item-features/${queryParams.id}`,
      data: queryParams,
      method: 'PUT'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

