export function item(state, data) {
  state.item = data
}

export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function gallery(state, data) {
  state.gallery = data
}

export function download(state, data) {
  state.download = data
}

export function features(state, data) {
  state.features = data
}
