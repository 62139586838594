export function save(ctx, data) {
  ctx.commit('cart', data)
}

export function initializeStore({ commit }, user) {
  commit('loadStateFromLocalStorage', user)
}

export function cartBranch(ctx, data) {
  ctx.commit('cartBranch', data)
}

export function cartOthers(ctx, data) {
  ctx.commit('cartOthers', data)
}

export function totalCartBranch(ctx, data) {
  ctx.commit('totalCartBranch', data)
}

export function totalCartOthers(ctx, data) {
  ctx.commit('totalCartOthers', data)
}

export function stock(ctx, data) {
  ctx.commit('stock', data)
}

export function clear(ctx) {
  ctx.commit('cart', [])
  ctx.commit('cartBranch', [])
  ctx.commit('cartOthers', [])
}
