import { api } from 'boot/axios.js'

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('help-medias', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/help-medias',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/help-medias',
      data: queryParams,
      method: 'POST'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('help-medias', queryParams),
      data: queryParams,
      method: 'PUT'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function remove(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('help-medias', queryParams),
      data: queryParams,
      method: 'DELETE'
    })
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}