export default {
  item: {},
  items: [],
  pagination: {},
  home: [],
  brands: [],
  priceRange: {},
  isFavorite: false,
  minimumPrice: '',
  fee: '',
  moreProductsSeller: []
}
