import { api } from 'boot/axios.js'

export async function helpCategories(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/help-categories',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('helpCategories', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function helpTopics(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/help-topics',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('helpTopics', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function saveHelpUseful(ctx, data) {
  try {
    const response = await api({
      url: '/help-topics-useful',
      method: 'POST',
      data: data
    })
    // ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}