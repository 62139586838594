import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/purchase-details',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function get(ctx, params = {}) {
  try {
    const response = await api({
      url: `/purchase-details/${params.id}`,
      params: params,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function purchaseNotification(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/purchase-details',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('purchaseNotification', response.data.items)
    return response
  } catch (e) {
    return e
  }
}
export async function shipmentLabel(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/shipment-label/${queryParams.id}`,
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('shipmentLabel', response.data.items)
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}
