import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/addresses',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, data) {
  try {
    const response = await api({
      url: `/addresses/${data}`,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/addresses',
      method: 'POST',
      data: data
    })
    ctx.commit('items', response.data)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function saveDefaultAddress(ctx, data) {
  try {
    const response = await api({
      url: `/default-address/${data.userId}`,
      method: 'POST',
      data: data
    })
    // ctx.commit('items', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function defaultAddress(ctx, data) {
  try {
    ctx.commit('item', data)
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, data) {
  try {
    const response = await api({
      url: `/addresses/${data.id}`,
      method: 'PUT',
      data: data
    })
    ctx.commit('items', response.data)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function remove(ctx, data) {
  try {
    const response = await api({
      url: `/addresses/${data.id}`,
      data: data,
      method: 'DELETE'
    })
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}

export async function getAutocompleteAddress(ctx, queryParams) {
  try {
    const response = await api({
      url: `https://microservices.marciano.com.mx/zip-codes/v1/zip-codes`,
      params: queryParams,
      method: 'GET'
    })
    // ctx.commit('items', response.data.items)
    // ctx.commit('pagination', response.data.pagination)
    return response.data.items
  } catch (e) {
    return e
  }
}
