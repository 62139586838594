export function item(state, data) {
  state.item = data
}

export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function purchaseNotification(state, data) {
  state.purchaseNotification = data
}

export function shipmentLabel(state, data) {
  state.shipmentLabel = data
}
