import { api } from 'boot/axios.js'
import axios from 'axios'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/products',
      params: {
        ...queryParams
      },
      method: 'get'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    ctx.commit('brands', response.data.brands)
    ctx.commit('priceRange', {
      max: response.data.maxPrice,
      min: response.data.minPrice
    })
    return response
  } catch (e) {
    throw e
  }
}

export async function highlight(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/products',
      params: {
        ...queryParams
      },
      method: 'get'
    })
    ctx.commit('highlight', response.data.items)
    return response
  } catch (e) {
    throw e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('products', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export async function getProduct(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('products', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('product', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export async function getAdmin(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/items', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}
export async function getProductMedias(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/item-media', queryParams),
      params: queryParams,
      method: 'GET'
    })
    // ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export async function minimumPrice(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/item-min-price',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('minimumPrice', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export async function fee(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/item-fee',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('fee', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export async function save(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/items',
      data: queryParams,
      method: 'POST'
    })
    return response
  } catch (e) {
    throw e
  }
}

export async function pauseProducts(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/paused-items',
      data: queryParams,
      method: 'POST'
    })
    return response
  } catch (e) {
    throw e
  }
}

export async function removePublication(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/${'admin/items'}/${queryParams.id}`,
      data: queryParams,
      method: 'DELETE'
    })
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/admin/items/${queryParams.id}`,
      data: queryParams,
      method: 'PUT'
    })
    return response
  } catch (e) {
    throw e
  }
}

export async function updatePublication(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/admin/item-availability/${queryParams.id}`,
      data: queryParams,
      method: 'PUT'
    })
    return response
  } catch (e) {
    throw e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}

export async function empty(ctx, queryParams = {}) {
  try {
    ctx.commit('items', [])
    ctx.commit('pagination', {})
    return response
  } catch (e) {
    throw e
  }
}

export async function home(ctx, queryParams = {}) {
  try {
    let products = [[], [], []]
    const resCat = await axios({
      url: `${process.env.API}categories`,
      params: {
        'filters[whereNull]': 'parentId',
        limit: 100
      },
      method: 'GET'
    })
    let lineIds = resCat.data.items.map(c => c.id)
    let i = 0
    while (i < 5) {
      const lineId = lineIds[Math.floor(Math.random() * lineIds.length)]
      const response = await axios({
        url: `${process.env.API}products`,
        params: {
          limit: 25,
          'filters[where][categoryId][eq]': lineId,
          'filters[orWhere][path][sw]': `${lineId},`,
          'filters[orWhere][path][eq]': lineId
        },
        method: 'GET'
      })
      if (response.data.items.length > 0) {
        let j = 0
        while (j < 3) {
          const random = Math.floor(Math.random() * response.data.items.length)
          if (response.data.items[random].imageAvailable) {
            products[j].push(response.data.items[random])
            j++
          }
        }
        lineIds = lineIds.filter(l => l != lineId)
        i++
      } else {
        lineIds = lineIds.filter(l => l != lineId)
      }
    }
    ctx.commit('home', products)
    return true
  } catch (e) {
    // console.log(e)
  }
}

export function isFavorite(ctx, data) {
  ctx.commit('isFavorite', data)
}

export async function moreProductsSeller(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/products',
      params: {
        ...queryParams
      },
      method: 'GET'
    })
    ctx.commit('moreProductsSeller', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    throw e
  }
}
