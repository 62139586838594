const errors = {
  DEFAULT:
    'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.',
  401: {
    DEFAULT: 'Debe estar autenticado para poder acceder a este recurso',
    AUTH_TOKEN_COMPROMISED:
      'Su sesión ha sido usada en otro dispositivo y ha sido desactivada. Vuelva a iniciar sesión para ingresar nuevamente a la plataforma.',
    AUTH_INVALID_CREDENTIALS: 'Usuario y/o contraseña incorrectos'
  },
  403: {
    DEFAULT: 'El recurso no fue encontrado en el servidor',
    NOT_ALLOWED:
      'Por favor agrega una dirección para completar el proceso.'
  },
  404: {
    DEFAULT: 'El recurso no fue encontrado en el servidor',
    ORDER_NOT_FOUND: 'La orden no existe.',
    PAYMENT_NOT_FOUND: 'El método de pago que elegiste no existe.',
    NOT_FOUND:
      'Lo siento, en este momento no se puede validar su cuenta o ya ha sido verificada.',
    102: 'Lo siento, no se puede encontrar información.'
  },
  422: {
    DEFAULT:
      'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.',
    YOU_HAVE_CREATED_AN_ORDER: 'Ya cuentas con una orden creada.',
    SESSION_EXPIRED: 'El tiempo de registro ha expirado.',
    AVAILABILITY_NOT_FOUND: 'La selección no concuerda.',
    UNAUTHORIZED: 'No estás autorizado para realizar esta acción.',
    INVALID_SESSION: 'La sesión actual no existe o es inválida.',
    INVALID_TOKEN:
      'El código no es válido, por favor verifica que tu código sea correcto.',
    100: {
      DEFAULT:
        'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte. ',
      email: 'El correo electrónico ya se encuentra registrado. ',
      token:
        'El código no es válido, por favor verifica que tu código sea correcto. ',
      rfc: 'Tu RFC no es válido. ',
      zipcode: 'Tu código postal no es válido. ',
      nickname: 'El nombre de usuario ya existe. Por favor, elija otro. ',
      phone: 'El teléfono ya se encuentra registrado. ',
      file: 'El archivo debe de ser menor de 8MB. '
    },
    INVALID_QUANTITY: 'La cantidad de producto no está disponible.',
    QUESTION_ANSWERED: 'La pregunta ha sido respondida.',
    DISPUTE_TIME_OVER:
      'Lo sentimos, el tiempo para abrir una disputa ha expirado. Recuerda que cuentas con 24 horas naturales desde que recibiste tu paquete para abrir una disputa.',
    DISPUTE_NOT_AVAILABLE: 'La disputa ha sido cerrada o denegada.',
    DISPUTE_CREATED: 'El producto ya tiene una disputa creada.',
    SHIPMENT_NULL: 'La compra aún no tiene un estado de envio.',
    ADDRESS_ON_ITEM: 'La dirección no se puede eliminar porque está configurada como uso de entrega o recolección.',
  },
  500: {
    DEFAULT:
      'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.'
  },
  503: {
    DEFAULT: 'El recurso no fue encontrado en el servidor',
    SERVICE_NOT_AVAILABLE:
      'Se presentó un error con tu señal de Internet o con el proveedor del Servicio de Paquetería. Por favor vuelve a cargar la página. Si este error persiste ponte en contacto con nosotros.'
  }
}

const getError = (status, code = 'DEFAULT', fields = []) => {
  if (fields.length > 0) {
    let _errors = []
    const _status = errors[status] ? status : 500
    const _code = 100
    for (const field of fields) {
      _errors.push(errors[_status][_code][field.field])
    }
    return _errors
  }
  const _status = errors[status] ? status : 500
  const _code = errors[_status][code] ? code : 'DEFAULT'
  return errors[_status][_code]
}

export default getError
