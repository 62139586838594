<template>
  <div class="ms-count">
    <q-btn
      class="btn-quantity border-right"
      :disable="min != null && modelValue <= min"
      flat
      @click="substract"
    >
      <i class="iconoir-minus" />
    </q-btn>
    <q-input
      :model-value="modelValue"
      class="input-count whithout-btns"
      mask="#####"
      color="white"
      type="number"
      borderless
      @update:model-value="val => $emit('update:modelValue', val)"
      @keyup="onKeyup"
    />
    <q-btn
      class="btn-quantity border-left"
      :disable="max != null && modelValue >= max"
      flat
      @click="add"
    >
      <i class="iconoir-plus" />
    </q-btn>
  </div>
</template>

<script>
/**
 *
 * MsCount Component
 *
 * @author Emmanuel Hernández <emmanuel.hernandez@marciano.com.mx>
 * @copyright 2022, Marciano Studio S.C.
 */
export default {
  name: 'MsCount',
  props: {
    modelValue: {
      type: [Number, String],
      default: '0'
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const add = () => {
      emit('update:modelValue', parseInt(props.modelValue) + 1)
    }

    const substract = () => {
      emit('update:modelValue', parseInt(props.modelValue) - 1)
    }

    const onKeyup = e => {
      e.target.value = props.modelValue
    }

    return {
      add,
      substract,
      onKeyup
    }
  }
}
</script>

<style lang="scss">
.ms-count {
  align-items: center;
  border: 1px solid $gris-minden-500;
  border-radius: 5px;
  display: flex;
  height: 50px;
  justify-content: center;
  text-align: center;
  width: 160px;

  .input-count {
    &.whithout-btns {
      .q-field__control-container {
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }

    .q-field__native {
      text-align: center;
    }
  }

  .btn-quantity {
    // border: 1px solid #51506a;
    color: $gris-minden-500;
    font-size: 16px;
    height: 48px;
    width: 48px;
  }
  .border-right {
    // border-right: 1px solid $gris-minden-500;
    border-radius: 5px 0 0 5px;
  }
  .border-left {
    // border-left: 1px solid $gris-minden-500;
    border-radius: 0 5px 5px 0;
  }
}
</style>
