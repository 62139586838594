export function item(state) {
  return state.item
}

export function items(state) {
  return state.items
}

export function promotionProducts(state) {
  return state.promotionProducts
}

export function promotionProductsPagination(state) {
  return state.promotionProductsPagination
}

