import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

import app from './app'
import auth from './auth'
import user from './user'
import customer from './customer'
import products from './products'
import favorites from './favorites'
import purchases from './purchases'
import machineries from './machineries'
import purchaseDetails from './purchase-details'
import mediaProducts from './media-products'
import machineryProducts from './machinery-products'
import branches from './branches'
import machineryBrands from './machinery-brands'
import machinerySeries from './machinery-series'
import purchaseStatus from './purchase-status'
import relatedProducts from './related-products'
import promotionProducts from './promotion-products'
import deliveryAddresses from './delivery-address'
import invoice from './invoice'
import modules from './modules'
import shoppingCart from './shopping-cart'
import warehouse from './warehouse'
import promotion from './promotion'
import categories from './categories'
import brands from './brands'
import countryMachineries from './country-machineries'
import productFeatures from './product-features'
import shippingService from './shipping-service'
import features from './features'
import featureGroups from './feature-groups'
import categoryFeaturesGroups from './category-features-groups'
import publish from './publish'
import conditions from './conditions'
import itemFeatures from './item-features'
import productCategories from './product-categories'
import shipment from './shipment'
import questions from './questions'
import shippings from './shippings'
import legals from './legals'
import payments from './payments'
import items from './items'
import purchaseMessages from './purchase-messages'
import purchaseRating from './purchase-rating'
import purchaseDisputes from './purchase-disputes'
import issues from './issues'
import disputeMessages from './dispute-messages'
import help from './help'
import bankData from './bank-data'
import transactions from './transactions'
import sellerRatings from './seller-ratings'
import shipmentTracking from './shipment-tracking'
import categoryFeatures from './category-features'
import followProfile from './follow-profile'
import helpMedia from './help-media'
import notifications from './notifications'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      app,
      auth,
      user,
      customer,
      products,
      favorites,
      purchases,
      machineries,
      purchaseDetails,
      mediaProducts,
      branches,
      purchaseStatus,
      machineryProducts,
      machineryBrands,
      machinerySeries,
      relatedProducts,
      promotionProducts,
      deliveryAddresses,
      invoice,
      modules,
      shoppingCart,
      warehouse,
      promotion,
      categories,
      brands,
      countryMachineries,
      productFeatures,
      shippingService,
      features,
      featureGroups,
      categoryFeaturesGroups,
      publish,
      conditions,
      itemFeatures,
      productCategories,
      shipment,
      questions,
      shippings,
      legals,
      payments,
      items,
      purchaseMessages,
      'purchase-rating': purchaseRating,
      purchaseDisputes,
      issues,
      disputeMessages,
      help,
      'bank-data': bankData,
      transactions,
      'seller-ratings': sellerRatings,
      shipmentTracking,
      categoryFeatures,
      followProfile,
      'help-media': helpMedia,
      notifications
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })

  return Store
})
