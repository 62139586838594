export function customer(state) {
  return state.customer
}

export function items(state) {
  return state.items
}

export function item(state) {
  return state.item
}

export function pagination(state) {
  return state.pagination
}

export function updateProfile(state) {
  return state.updateProfile
}
