import { api } from 'boot/axios.js'
import { getCookies } from 'boot/cookies.js'

export async function login(ctx, data) {
  try {
    const response = await api({
      url: 'auth/token',
      method: 'POST',
      data: data
    })

    const options = {
      secure: process.env.PROD,
      expires: parseInt(response.data.tokenLife) / 60 / 1000
    }

    const cookies = getCookies

    cookies.set('auth_token', response.data.token, options)
    // cookies.set('auth_token', 'SpCxOXRC4szrCxFMxTonX1ac1yUjWk9pKY8YEW1XNuPF6P3O2jczkga8fJIcocW39b4w2Iw7sKKoRgHzSVLJlvdgASK8LEEOzqXFadO512byuxBjkFfJ6B9EQOzrfQ40KRlImUPCYgfJdCHK7z2p6402SWD1lwEJTlU66Wg6gjf0KIpznjm3cVxlMAht3aS6pVUahaMDbxEFDy0pEEIfuCiZw0NpEgXFBfdftRYNgwyi95Yhf5XHgYquotmiEkJ', options)
    cookies.set('auth_token_life', response.data.tokenLife, options)
    cookies.set('auth_token_type', response.data.type, options)

    options.expires = parseInt(response.data.refreshTokenLife) / 60 / 1000
    cookies.set('refresh_token', response.data.refreshToken, options)
    // cookies.set('refresh_token', 'nGHydjW4Euy7TXWp2BrfPvUQwEIXd11oKlmVQC66XPpdZq4T0qK7kvW2VOzgNbhv3ATi2RCWjzMvH2rHvpZ4yUA6ksFz12NnhqJrqNPkbslgn7gjZQDybacZLaBu4AFW0TYSvq3j4BctywtTu9V6BzF4M90j1fgwTefQPg4xCiLLAfXUuW9SvffBFVPiYDIrRmWpDB4aLVe7OQTa6sHy5fNSqsJaBA9GYy8r0F9aZUN4CgMYTOzWYo9oGNb9PF2', options)
    cookies.set('refresh_token_life', response.data.refreshTokenLife, options)

    ctx.commit('isAuthenticated', true)

    api.defaults.headers.common['Authorization'] =
      'Bearer ' + response.data.token

    return response
  } catch (e) {
    return e
  }
}
export async function autoLogin(ctx, data) {
  try {

    const options = {
      secure: process.env.PROD,
      expires: parseInt(data.tokenLife) / 60 / 1000
    }

    const cookies = getCookies

    cookies.set('auth_token', data.token, options)
    cookies.set('auth_token_life', data.tokenLife, options)
    cookies.set('auth_token_type', data.type, options)

    options.expires = parseInt(data.refreshTokenLife) / 60 / 1000
    cookies.set('refresh_token', data.refreshToken, options)
    cookies.set('refresh_token_life', data.refreshTokenLife, options)

    ctx.commit('isAuthenticated', true)

    api.defaults.headers.common['Authorization'] =
      'Bearer ' + data.token

    return response
  } catch (e) {
    return e
  }
}

export async function recover(ctx, data) {
  try {
    const response = await api({
      url: 'auth/recover-password',
      method: 'POST',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}

export async function reset(ctx, data) {
  try {
    const response = await api({
      url: 'auth/reset-password',
      method: 'POST',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}

export async function logout(ctx, data = {}) {
  try {
    const response = await api({
      url: 'auth/revoke',
      method: 'POST',
      data: data
    })

    const cookies = getCookies

    cookies.remove('auth_token')
    cookies.remove('auth_token_life')
    cookies.remove('auth_token_type')

    cookies.remove('refresh_token')
    cookies.remove('refresh_token_life')

    ctx.commit('isAuthenticated', false)

    delete api.defaults.headers.common['Authorization']

    return response
  } catch (e) {
    return e
  }
}

export function clear(ctx) {
  ctx.commit('isAuthenticated', false)

  const cookies = getCookies

  cookies.remove('auth_token')
  cookies.remove('auth_token_life')
  cookies.remove('auth_token_type')
  cookies.remove('refresh_token')
  cookies.remove('refresh_token_life')

  delete api.defaults.headers.common['Authorization']
}

export function isAuthenticated(ctx, data) {
  ctx.commit('isAuthenticated', data)
}
