import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/transactions',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    throw e
  }
}

export async function getBalance(ctx, queryParams = {}) {
  try {
    const wallet = await api({
      url: '/admin/transactions',
      params: { ...queryParams, 'filters[where][movementType][eq]': 'WALLET' },
      method: 'GET'
    })
    const sale = await api({
      url: '/admin/transactions',
      params: { ...queryParams, 'filters[where][movementType][eq]': 'SALE' },
      method: 'GET'
    })
    let balanceWallet =
      wallet.data && wallet.data.items.length > 0
        ? wallet.data.items[0].balance
        : 0

    let totalWallet = wallet.data?.pagination ? wallet.data.pagination.total : 0
    let balanceSale =
      sale.data && sale.data.items.length > 0 ? sale.data.items[0].balance : 0

    let totalSale = sale.data?.pagination ? sale.data.pagination.total : 0
    let objValues = {
      balanceWallet: parseFloat(balanceWallet),
      totalWallet: parseFloat(totalWallet),
      balanceSale: parseFloat(balanceSale),
      totalSale: parseFloat(totalSale)
    }
    ctx.commit('item', objValues)
    return wallet
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/transactions', queryParams),
      params: {},
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}
