export function item(state, data) {
  state.item = data
}

export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function products(state, data) {
  state.products = data
}

export function salesAmount(state, data) {
  state.salesAmount = data
}

export function salesNotification(state, data) {
  state.salesNotification = data
}

export function sales(state, data) {
  state.sales = data
}

export function sale(state, data) {
  state.sale = data
}
