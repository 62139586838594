import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/features',
      params: queryParams,
      method: 'get'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function list(ctx, data = {}) {
  try {
    const response = await api({
      url: `/admin/feature-list/${data}`,
      method: 'get'
    })
    ctx.commit('items', response.data)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function empty(ctx, queryParams = {}) {
  try {
    ctx.commit('items', [])
    ctx.commit('pagination', {})
    return response
  } catch (e) {
    return e
  }
}
