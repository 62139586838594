import { es } from 'date-fns/locale'
import { formatRelative } from 'date-fns'
import { add } from 'date-fns'

export default (value, day = 0) => {
  return formatRelative(
    add(new Date(value), { hours: 0, days: day }),
    new Date(),
    { locale: es }
  )
}
//hours: -5,
