import { api } from 'boot/axios.js'

export async function saveDraft(ctx, product = {}) {
  try {
    ctx.commit('item', product)
    return product
  } catch (e) {
    return e
  }
}

