export function agcoSelect(state, data) {
  state.agcoSelect = data
}

export function sidebarCart(state, data) {
  state.sidebarCart = data
}

export function shoppingData(state, data) {
  state.shoppingData = data
}

export function dialogLogin(state, data) {
  state.dialogLogin = data
}

export function dialogRegister(state, data) {
  state.dialogRegister = data
}

export function dialogConfirmAccount(state, data) {
  state.dialogConfirmAccount = data
}

export function dialogHelp(state, data) {
  state.dialogHelp = data
}

export function dialogMenuMobile(state, data) {
  state.dialogMenuMobile = data
}

export function dialogForgotPassword(state, data) {
  state.dialogForgotPassword = data
}

export function dialogForgotPasswordValidate(state, data) {
  state.dialogForgotPasswordValidate = data
}

export function dialogResetConfirm(state, data) {
  state.dialogResetConfirm = data
}

export function dialogCheckEmail(state, data) {
  state.dialogCheckEmail = data
}

export function loginData(state, data) {
  state.loginData = data
}

export function appIsLoading(state, data) {
  state.appIsLoading = data
}

export function toNav(state, data) {
  state.toNav = data
}
