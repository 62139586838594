import { api } from 'boot/axios.js'
import axios from 'axios'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/bank-data',
      params: {
        ...queryParams
      },
      method: 'get'
    })
    let item = {}
    if (response.data.items.length > 0) {
      item = response.data.items[0]
    }
    ctx.commit('item', item)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    throw e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('admin/bank-data', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export async function save(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/bank-data',
      data: queryParams,
      method: 'POST'
    })
    return response
  } catch (e) {
    throw e
  }
}

export async function update(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrlFormData('admin/bank-data', queryParams),
      data: queryParams,
      method: 'PUT'
    })
    return response
  } catch (e) {
    throw e
  }
}

function parseUrlFormData(state, params) {
  if (state && params) {
    return `/${state}/${params.get('id')}`
  }
  return `/${state}`
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}
