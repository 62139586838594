export default function () {
  return {
    item: {},
    items: [
      // {
      //   label: 'Express',
      //   shortDescription: '(24 horas)',
      //   description: 'Hasta 1 días después de tu compra + tiempo de la paquetería seleccionada.',
      //   value: 'EXPRESS',
      //   color: '#0EC06F'
      // },
      {
        label: 'Rápido',
        shortDescription: '(24 a 48 horas)',
        description: 'Hasta 2 días después de tu compra  + tiempo de la paquetería seleccionada.',
        value: 'FAST',
        color: '#E2B800'
      },
      {
        label: 'Normal',
        shortDescription: '(hasta 5 días habiles)',
        description: 'Hasta 5 días después de tu compra  + tiempo de la paquetería seleccionada.',
        value: 'NORMAL',
        color: '#51506A'
      }
    ],
    pagination: {}
  }
}
