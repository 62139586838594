export function item(state, data) {
  state.item = data
}

export function items(state, data) {
  state.items = data
}

export function sellerTerms(state, data) {
  state.sellerTerms = data
}
export function buyerTerms(state, data) {
  state.buyerTerms = data
}
export function privacyTerms(state, data) {
  state.privacyTerms = data
}
export function termsConditions(state, data) {
  state.termsConditions = data
}
export function usagePolicy(state, data) {
  state.usagePolicy = data
}

export function pagination(state, data) {
  state.pagination = data
}
