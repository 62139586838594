export function item(state) {
  return state.item
}

export function items(state) {
  return state.items
}

export function sellerTerms(state) {
  return state.sellerTerms
}
export function buyerTerms(state) {
  return state.buyerTerms
}
export function privacyTerms(state) {
  return state.privacyTerms
}
export function termsConditions(state) {
  return state.termsConditions
}
export function usagePolicy(state) {
  return state.usagePolicy
}

export function pagination(state) {
  return state.pagination
}
