const routes = [
  {
    path: '',
    component: () => import('src/layouts/main-layout.vue'),
    children: [
      {
        path: '',
        name: 'home-ssr',
        component: () => import('pages/index-ssr.vue')
      },
      {
        path: '',
        name: 'home',
        component: () => import('pages/index-page.vue')
      },
      {
        path: 'productos',
        name: 'products',
        component: () => import('src/pages/products/index-minden.vue')
      },
      {
        path: 'productos/:id',
        name: 'product-detail',
        component: () => import('src/pages/products/detail-minden.vue')
      },
      {
        path: 'productos-en-revision/:id',
        name: 'product-detail-process-revision',
        component: () => import('src/pages/products/detail-minden.vue')
      },
      {
        path: 'categorias',
        name: 'categories',
        component: () => import('src/pages/products/all-categories.vue')
      },
      {
        path: 'como-funciona',
        name: 'how-work',
        component: () => import('pages/how-work-page.vue')
      },
      {
        path: 'profile/:nickname',
        name: 'profile',
        component: () => import('pages/profile-page.vue')
      },
      {
        path: 'profile/:nickname/calificaciones',
        name: 'ratings',
        component: () => import('pages/reviews-page.vue')
      }
    ]
  },
  {
    path: '/auth',
    component: () => import('src/layouts/login-layout.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('pages/auth/index-page.vue')
      },
      {
        path: 'registro',
        name: 'register',
        component: () => import('pages/auth/register-minden.vue')
      },
      {
        path: 'revisar-correo',
        name: 'verify-email',
        component: () => import('pages/auth/verify-account-minden.vue')
      },
      {
        path: 'verify-account/:token',
        name: 'verify-ssr',
        component: () => import('pages/verify-ssr.vue')
      },
      {
        path: 'verify-account/:token',
        // path: 'verificar-cuenta',
        name: 'verify',
        component: () => import('pages/auth/confirmation-register-minden.vue')
      },
      {
        // path: 'verify-account/:token',
        path: 'confirmacion',
        name: 'verify-confirmation',
        component: () => import('pages/auth/confirmation-register-minden.vue')
      },
      {
        path: 'recuperar-contrasena',
        name: 'recovery-password',
        component: () => import('pages/auth/recovery-password.vue')
      },
      {
        path: 'reset-password/:token',
        name: 'reset-password',
        component: () => import('pages/auth/reset-password.vue')
      }
    ]
  },
  {
    path: '/publicar-producto',
    component: () => import('src/layouts/publish-layout.vue'),
    children: [
      {
        path: '/publicar-producto/:tab?',
        name: 'publish',
        component: () => import('pages/publish/index.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('src/layouts/cart-layout.vue'),
    children: [
      {
        path: 'mi-carrito',
        name: 'my-shopping-cart',
        component: () => import('src/pages/my-shopping-cart-minden.vue')
      },
      {
        path: 'direccion/:id',
        name: 'edit-address',
        component: () => import('src/pages/delivery-address-minden')
      },
      {
        path: '/direccion/:type',
        name: 'address',
        component: () => import('src/pages/delivery-address-minden')
      },
      {
        path: 'mis-direcciones-de-envio',
        name: 'select-address',
        component: () => import('src/pages/select-default-address-minden')
      }
    ]
  },
  {
    path: '/',
    component: () => import('src/layouts/payments-layout.vue'),
    children: [
      {
        path: '/checkout',
        name: 'payments',
        component: () => import('pages/payments-page.vue')
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('pages/order-page.vue')
      }
    ]
  },
  {
    path: '/legal/',
    component: () => import('src/layouts/legals-layout.vue'),
    children: [
      {
        path: '/legal/terminos-y-condiciones-de-venta',
        name: 'terms-conditions-seller',
        component: () => import('src/pages/terms-conditions-seller.vue')
      },
      {
        path: '/legal/terminos-y-condiciones-de-compra',
        name: 'terms-conditions-buyer',
        component: () => import('src/pages/terms-conditions-buyer.vue')
      },
      {
        path: '/legal/terminos-y-condiciones',
        name: 'terms-conditions',
        component: () => import('src/pages/terms-conditions.vue')
      },
      {
        path: '/legal/aviso-de-privacidad',
        name: 'privacy-terms',
        component: () => import('src/pages/privacy-terms.vue')
      },
      {
        path: '/legal/aviso-de-privacidad/:id',
        name: 'privacy-terms-version',
        component: () => import('src/pages/privacy-terms.vue')
      },
      {
        path: '/legal/politica-de-cookies',
        name: 'terms-cookies',
        component: () => import('src/pages/terms-cookies.vue')
      },
      {
        path: '/legal/lista-de-costos',
        name: 'cost-list',
        component: () => import('src/pages/cost-list.vue')
      },
      {
        path: '/legal/politica-de-uso',
        name: 'usage-policy',
        component: () => import('src/pages/usage-policy.vue')
      },
      {
        path: '/ayuda',
        name: 'help',
        component: () => import('src/pages/account/help/help-index.vue')
      },
      {
        path: '/ayuda/:id',
        name: 'help-category',
        component: () => import('src/pages/account/help/help-category.vue')
      },
      {
        path: '/ayuda/detalle/:id',
        name: 'help-subcategory',
        component: () => import('src/pages/account/help/help-subcategory.vue')
      }
    ]
  },
  {
    path: '',
    component: () => import('src/layouts/confirmation-layout.vue'),
    children: [
      {
        path: '/confirmacion-de-compra',
        name: 'buy-success',
        component: () => import('src/pages/order-confirmation-minden.vue')
      },
      {
        path: '/error-de-pago',
        name: 'buy-error',
        component: () => import('src/pages/order-confirmation-minden-error.vue')
      }
    ]
  },
  {
    path: '/confirmacion-de-producto',
    component: () => import('src/layouts/confirmation-layout.vue'),
    children: [
      {
        path: '',
        name: 'publish-confirmation',
        component: () => import('src/pages/publish-confirmation-minden.vue')
      }
    ]
  },
  {
    path: '/mi-cuenta',
    component: () => import('src/layouts/account-layout.vue'),
    children: [
      {
        path: '',
        name: 'my-profile',
        component: () => import('src/pages/account/my-profile.vue')
      },
      {
        path: 'mis-datos',
        name: 'my-data',
        component: () => import('src/pages/profile/my-data')
      },
      {
        path: 'datos-bancarios',
        name: 'bank-data',
        component: () => import('src/pages/account/bank-data.vue')
      },
      {
        path: 'favoritos',
        name: 'my-favorites',
        component: () => import('src/pages/account/my-favorites.vue')
      },
      {
        path: 'mi-tienda',
        name: 'my-store',
        component: () => import('src/pages/account/my-store.vue')
      },
      {
        path: 'mi-tienda/:trackingStatus?',
        name: 'my-store-tracking-status',
        component: () => import('src/pages/account/my-store.vue')
      },
      {
        path: 'mi-tienda/mis-ventas',
        name: 'my-sales',
        component: () => import('src/pages/account/sales/my-sales-minden.vue')
      },
      {
        path: 'venta/:id',
        name: 'detail-sale',
        component: () =>
          import('src/pages/account/sales/detail-sale-minden.vue')
      },
      {
        path: 'mis-pedidos',
        name: 'my-orders',
        component: () => import('src/pages/account/orders/my-orders-minden.vue')
      },
      {
        path: 'enviar-mensaje/:id',
        name: 'send-message',
        component: () => import('src/pages/account/orders/chat-minden.vue')
      },
      {
        path: 'enviar-mensaje/:id',
        name: 'message-seller',
        component: () => import('src/pages/account/orders/chat-minden.vue')
      },
      {
        path: 'entrega-en-curso/:id',
        name: 'delivery-progress',
        component: () =>
          import('src/pages/account/orders/delivery-progress-minden.vue')
      },
      {
        path: 'compra-entregada/:id',
        name: 'purchase-delivered',
        component: () =>
          import('src/pages/account/orders/purchase-delivered-minden.vue')
      },
      {
        path: 'calificar-producto/:id',
        name: 'rating-product',
        component: () =>
          import('src/pages/account/orders/rating-product-minden.vue')
      },
      {
        path: 'preguntas',
        name: 'questions',
        component: () => import('src/pages/account/questions.vue')
      },
      {
        path: 'responder-pregunta/:id',
        name: 'answer-question',
        component: () => import('src/pages/account/answer-question.vue')
      },
      {
        path: 'calificaciones',
        name: 'seller-ratings',
        component: () => import('src/pages/account/seller-ratings.vue')
      },
      {
        path: 'mi-balance',
        name: 'my-balance',
        component: () => import('src/pages/account/my-balance.vue')
      },
      {
        path: 'mis-compras/mensajes',
        name: 'messages',
        component: () => import('src/pages/account/messages.vue')
      },
      {
        path: 'mi-tienda/mensajes',
        name: 'messages-seller',
        component: () => import('src/pages/account/messages.vue')
      },
      {
        path: 'mis-compras/disputas',
        name: 'purchase-disputes',
        component: () =>
          import('src/pages/account/disputes/purchase-disputes.vue')
      },
      {
        path: 'mi-tienda/disputas',
        name: 'dispute-seller',
        component: () =>
          import('src/pages/account/disputes/purchase-disputes.vue')
      },
      {
        path: 'mis-compras/disputas/menu-de-disputa',
        name: 'dispute-menu',
        component: () => import('src/pages/account/disputes/dispute-menu.vue')
      },
      {
        path: 'mis-compras/disputas/detalle-de-disputa/:id&:purchaseId',
        name: 'dispute-detail',
        component: () => import('src/pages/account/disputes/dispute-detail.vue')
      },
      {
        path: 'mis-compras/disputas/enviar-mensaje/:id',
        name: 'send-message-dispute-minden',
        component: () =>
          import('src/pages/account/disputes/send-message-dispute-minden.vue')
      },
      {
        path: 'mi-tienda/disputas/enviar-mensaje/:id',
        name: 'dispute-minden-seller',
        component: () =>
          import('src/pages/account/disputes/send-message-dispute-minden.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('src/pages/error-404.vue')
  }
]

export default routes
