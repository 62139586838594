import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/purchase-disputes',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function querySeller(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/admin/purchase-disputes-seller',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, data = {}) {
  try {
    const response = await api({
      url: `/admin/purchase-disputes/${data.id}`,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}
export async function getSeller(ctx, data = {}) {
  try {
    const response = await api({
      url: `/admin/purchase-disputes-seller/${data.id}`,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function view(ctx, data = {}) {
  try {
    const response = await api({
      url: `/admin/purchase-disputes/${data}`,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response.data
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/admin/purchase-disputes',
      method: 'POST',
      data: data
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}
export async function saveMedias(ctx, data) {
  try {
    const response = await api({
      url: '/admin/message-medias',
      method: 'POST',
      data: data
    })
    // ctx.commit('item', response.data)
    return response
  } catch (e) {
    throw e
  }
}

export function products(ctx, data) {
  ctx.commit('products', data)
}
