import { api } from 'boot/axios.js'

export async function get(ctx, params = {}) {
  try {
    const response = await api({
      url: `/shipment-tracking/${params.id}`,
      // params: params,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}
export async function empty(ctx, params = {}) {
  try {
    ctx.commit('item', {})
    // ctx.commit('pagination', response.data.pagination)
  } catch (e) {
    return e
  }
}

