import { boot } from 'quasar/wrappers'

export default boot(({ app, store }) => {
  app.use(store)

  // if (process.env.CLIENT) {
  //   let user = store.getters['user/me']
  //   store.dispatch('shoppingCart/initializeStore', 'shippingCart-${me.nickname'})
  // }
})
