export default {
  item: {},
  items: [],
  pagination: {},
  sellerTerms: {},
  buyerTerms: {},
  privacyTerms: {},
  termsConditions: {},
  usagePolicy: {}
}
