export function item(state, data) {
  state.item = data
}

export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function helpCategories(state, data) {
  state.helpCategories = data
}

export function helpTopics(state, data) {
  state.helpTopics = data
}