import { LocalStorage } from 'quasar'

export function cart(state, data) {
  state.cart = data
}

export function cartBranch(state, data) {
  state.cartBranch = data
}

export function cartOthers(state, data) {
  state.cartOthers = data
}

export function totalCartBranch(state, data) {
  state.totalCartBranch = data
}

export function totalCartOthers(state, data) {
  state.totalCartOthers = data
}

export function stock(state, data) {
  state.stock = data
}

export function loadStateFromLocalStorage(state, user) {
  // console.log('entra loadStateFromLocalStorage', process.env.CLIENT, user)
  if (process.env.CLIENT) {
    const cart = LocalStorage.getItem(user)
    // console.log(cart)
    if (cart) {
      state.cart = JSON.parse(JSON.stringify(cart))
    } else {
      state.cart = []
    }
  }
}
